.hospitals {
  width: 95%;
  height: 100vh;
  flex: 1;
  overflow-y: auto;
}

@media screen and (width > 2560px) {
  .hospitals {
    width: 97%;
  }
}

@media screen and (max-width: 2560px) {
  .hospitals {
    width: 97%;
  }
}

@media screen and (max-width: 1440px) {
  .hospitals {
    width: 95%;
  }
}

@media screen and (max-width: 1024px) {
  .hospitals {
    width: 93%;
  }
}

@media screen and (max-width: 768px) {
  .hospitals {
    width: 92%;
  }
}

@media screen and (max-width: 425px) {
  .hospitals {
    width: 85%;
  }
}

.select-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.location-default-item {
  display: flex;
}

.location-list-item {
  margin: 0px 20px;
  color: #1b5299;
  font-weight: bold;
}

.select-location {
  width: 100%;
  height: 65px;
  border-radius: 0px !important;
  background-color: #f5f6f8;
}

.patient-card {
  height: 60px;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-right: 1px solid #d5d5d5;
  cursor: pointer;
}

.patient-card-selected {
  border-left: 2px solid #1b5299;
  border-right: 2px solid #1b5299;
  border-bottom: 2px solid #1b5299;
}

.patient-card-details {
  margin: 0px 5px;
}

.patient-card:hover {
  background-color: #f7f9f9;
}

.patient-image {
  margin: 3px 3px;
}

.patient-logo {
  color: #555;
}

.patient-list-container {
  display: flex;
}

.patient-section {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}

::-webkit-scrollbar {
  height: 5px;
  border: 1px solid #d5d5d5;
}

.no-patient-available {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 60px;
}

.no-patient-selected {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #181717;
  border: 1px solid #888;
}

.search-bar {
  height: 65px !important;
}
