.patient-reports {
  border: 1px solid #1534a0;
  margin: 15px;
}

.styled-custom-tabs {
  background-color: #004c91;
}

.styled-tab {
  color: rgb(252, 254, 255) !important;
}

.custom-indicator {
  background-color: orange !important;
}

.custom-tab-panel{
  width: 100%;
}